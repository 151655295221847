var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"customers-pending-products-view"}},[_c('material-card',{attrs:{"color":"primary","icon":"mdi-playlist-plus"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Customers Pending Products ")]},proxy:true}])},[_c('v-data-table',{staticClass:"mr-2 ml-2",attrs:{"headers":_vm.headers,"items":_vm.customers,"loading":_vm.loading,"search":_vm.query.keyword,"loading-text":"Loading Batches... Please wait"},on:{"update:search":function($event){return _vm.$set(_vm.query, "keyword", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"placeholder":"Search","hide-details":"","clearable":"","append-icon":"mdi-magnify","single-line":"","dense":""},model:{value:(_vm.query.keyword),callback:function ($$v) {_vm.$set(_vm.query, "keyword", $$v)},expression:"query.keyword"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.fname)+" "+_vm._s(item.customer.lname)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.addProduct(item.customer)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-playlist-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Add products to customer")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.addToCart(item.customer)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cart-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Add customer to order list")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'customer_detail', params: { id: item.customer.id } })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-search")])],1)]}}],null,true)},[_c('span',[_vm._v("View customer record")])])]}},{key:"no-data",fn:function(){return [_vm._v(" No customers pending products found. ")]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }